import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  AccFLnameValidator,
  MaxChar,
  NumberValidator,
} from "components/common/Validators";
import { URL_INITIATE_RECHARGE } from "constants";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Checkout from "./Checkout";
import FormatDigits from "./FormatDigits";

function calcGST(price, rate) {
  return (price * rate) / 100;
}

const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const RechargeCard = ({ data, user, setShowMain, showLoading, hideLoading }) => {
  const axiosPrivate = useAxiosPrivate()
  const [show, setShow] = useState(false);
  const [error, setError] = useState({ field: null, error: null });

  const handleRecharge = async (event) => {
    event.preventDefault();
    let gst = event.target.gstin.value;
    if (gst && !gstRegex.test(gst)) {
      setError({
        field: "gstin",
        error: "Please provide a valid GSTIN",
      });
    } else {
      let uObj = {
        first_name: event.target.fname.value,
        last_name: event.target.lname.value,
        gstin: event.target.gstin.value,
        email: event.target.email.value,
        address: event.target.address.value,
        city: event.target.city.value,
        state: event.target.state.value,
        pincode: event.target.pincode.value,
      };
      showLoading()
      await axiosPrivate
        .post(
          `${URL_INITIATE_RECHARGE}`,
          {
            createOrder: true,
            plan_id: data.id,
            amount: data.price,
            user_profile: uObj,
          }
        )
        .then((res) => {
          setShow(false);
          setShowMain(false);
          Checkout(axiosPrivate, user, res?.data?.data, data.id);
          hideLoading()
        })
        .catch((error) => {
          toast.error("Something went wrong. Please contact help desk", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          hideLoading()

        });
    }
  };
  if (data.id == "tag")
    return <div className="mb-4 bg-gray-200 p-1"><span className="font-bold text-slate-800 ">{data.name}</span></div>
  else
    return (
      <div className="card mb-8 px-4 h-40 relative">
        <span className={`${data.tag == "New" ? "bg-cyan-700" : "bg-green-700"} text-white text-xxs px-2 absolute left-4 -top-2`}>
          {data.tag}
        </span>
        <div className="flex justify-between align-middle pb-2 mt-4 text-slate-800 ">
          <span className="text-md">
            ₹ {FormatDigits(data.price)}
            <h6 className="text-xs text-slate-500">plan</h6>
          </span>


          {data?.account ? (<span className="text-md">
            <span>{data.account}</span>
            <h6 className="text-xs text-slate-500">admin accounts</h6>
          </span>) : (data?.projects ? (<span className="text-md">
            <span>{data.projects !== 0 && data.projects}</span>
            <h6 className="text-sm text-slate-500">{data.projects == 1 ? "project" : "projects"}</h6>
          </span>) : data?.forms && <span className="text-md">
            <span>{(data.forms !== 0 && data.forms !== "unlimited") ? FormatDigits(data.forms) : "Unlimited"}</span>
            <h6 className="text-sm text-slate-500">responses</h6>
          </span>)}
          <span className="text-md">
            <span>{FormatDigits(data.validity)} days</span>
            <h6 className="text-xs text-slate-500">validity</h6>
          </span>
        </div>
        <button
          className="button bg-slate-800 text-white text-sm my-3 w-full"
          onClick={() => {
            setShow(true);
          }}
        >
          Buy
        </button>
        <div className="border-t pt-1">
          {data.backup && <span className="text-xxs">
            Images will be removed in {FormatDigits(data.backup)} days after your pack expires
          </span>}
          {data.info && <span className="text-xxs">
            {data.info}
          </span>}
        </div>

        <Transition appear show={show} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => setShow(false)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-70" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                  <form onSubmit={handleRecharge}>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 border-b pb-2"
                    >
                      Recharge Summary
                    </Dialog.Title>
                    <div className="my-4">
                      <div className="text-md mb-3">
                        <div className="flex justify-between">
                          <span>{FormatDigits(data.forms)} responses </span>
                          <span>₹ {FormatDigits(data.price)}.00</span>
                        </div>
                        <div className="flex justify-between">
                          <span>GST@18%</span>
                          <span>₹ {FormatDigits(calcGST(data.price, 18))}</span>
                        </div>
                        <div className="my-1 border-t border-b py-1 flex justify-between font-semibold">
                          <span>Total Payable Amount</span>
                          <span>
                            ₹ {FormatDigits(data.price + calcGST(data.price, 18))}
                          </span>
                        </div>
                      </div>
                      <h6 className="font-semibold text-center my-4">
                        Billing Details
                      </h6>
                      <div className="flex gap-4">
                        <input
                          className="input"
                          id="fname"
                          name="fname"
                          defaultValue={user?.first_name}
                          type="text"
                          placeholder="First Name"
                          autoComplete="first_name"
                          required
                          onInput={(e) => MaxChar(e, 25)}
                          onKeyPress={AccFLnameValidator}
                        />
                        <input
                          className="input"
                          id="lname"
                          name="lname"
                          type="text"
                          placeholder="Last Name"
                          autoComplete="last_name"
                          required
                          defaultValue={user?.last_name}
                          onInput={(e) => MaxChar(e, 25)}
                          onKeyPress={AccFLnameValidator}
                        />
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 relative">
                          <input
                            className="input"
                            id="gstin"
                            name="gstin"
                            type="text"
                            placeholder="GST No (Optional)"
                            autoComplete="gst"
                            defaultValue={user?.gstin}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 15)
                                .toUpperCase();
                              if (error.field === "gstin") setError({});
                            }}
                          />
                          {error.field === "gstin" && (
                            <div
                              className="invalid-tooltip"
                              id={`error-${error.field}`}
                            >
                              {error.error}
                            </div>
                          )}
                        </div>
                        <div className="flex-1">
                          <input
                            className="input"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email id"
                            defaultValue={user?.email}
                            autoComplete="email"
                            required
                            onInput={(e) => MaxChar(e, 40)}
                          />
                        </div>
                      </div>

                      <div className="flex gap-4">
                        <input
                          className="input"
                          id="address"
                          name="address"
                          type="text"
                          defaultValue={user?.address}
                          placeholder="Address"
                          autoComplete="address"
                          onInput={(e) => MaxChar(e, 150)}
                        />
                        <input
                          className="input"
                          id="city"
                          name="city"
                          type="text"
                          placeholder="City"
                          autoComplete="city"
                          defaultValue={user?.city}
                          required
                          onInput={(e) => MaxChar(e, 50)}
                        />
                      </div>
                      <div className="flex gap-4">
                        <input
                          className="input"
                          id="state"
                          name="state"
                          type="text"
                          placeholder="State"
                          autoComplete="state"
                          required
                          defaultValue={user?.state}
                          onInput={(e) => MaxChar(e, 75)}
                        />
                        <input
                          className="input"
                          id="pincode"
                          name="pincode"
                          type="number"
                          placeholder="Pincode"
                          autoComplete="pin"
                          required
                          defaultValue={user?.pincode}
                          onInput={(e) => MaxChar(e, 10)}
                          onKeyPress={(e) => NumberValidator(e)}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <button
                        type="submit"
                        className="button bg-slate-700 text-white px-8"
                      >
                        Pay Now
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
};

export default RechargeCard;
